import React from 'react';
// import Card from 'react-bootstrap/Card';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faReact, faGitAlt } from '@fortawesome/free-brands-svg-icons';
import './style/Portfolio.css';

function PortfolioItem() {
  return (
    <>
      <div className="content">
      </div>
    </>
  );
}

export default PortfolioItem;