import React from 'react';
// import Card from 'react-bootstrap/Card';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faReact, faGitAlt } from '@fortawesome/free-brands-svg-icons';
import './style/ErrorPage.css';

function ErrorPage() {
  return (
    <>
      <div className="content">
      </div>
    </>
  );
}

export default ErrorPage;